import React, { useState } from 'react';

import { PlanogramVersion } from 'types';
import { planogramsModel } from 'models';
import { AudioAsset, AudioSettingsType } from 'types/audio';

import AudioSettingsCard from './AudioSettingsCard';

const DEFAULT_BACKGROUND_COLOR = '#6238f9';
const DEFAULT_VOLUME = 80;

const isAudioSettings = (audio: AudioAsset | {} | null | undefined): audio is AudioAsset =>
  Boolean(audio && Object.keys(audio).length > 0);

function planogramVersionToAudioSettings(planogramVersion: PlanogramVersion): AudioSettingsType | undefined {
  const { audio, otherAssets, audioBackgroundColor, volume } = planogramVersion;

  return isAudioSettings(audio)
    ? {
      audioId: audio?.id,
      audioBackgroundColor,
      stopOtherAssetId: otherAssets.find(asset => asset.title === 'stop')?.id,
      muteOtherAssetId: otherAssets.find(asset => asset.title === 'mute')?.id,
      unmuteOtherAssetId: otherAssets.find(asset => asset.title === 'unmute')?.id,
      volume,
    }
    : undefined;
}

interface AudioSettingsProps {
  planogramVersion: PlanogramVersion;
  planogramId: number;
  handleServerError: (err: any) => void;
  setSuccessActionMessages: (messages: string[]) => void;
}

export function AudioSettings({
  planogramVersion,
  planogramId,
  handleServerError,
  setSuccessActionMessages,
}: AudioSettingsProps) {
  const hasAudio = isAudioSettings(planogramVersion.audio);
  const [expanded, setExpanded] = useState(hasAudio);
  const [audioSettings, setAudioSettings] = useState<AudioSettingsType | undefined>(
    planogramVersionToAudioSettings(planogramVersion),
  );

  const onSaveAudioSettings = async (settings: AudioSettingsType | undefined) =>
    planogramsModel
      .editVersion(
        planogramId,
        planogramVersion.id,
        settings ?? { audioBackgroundColor: DEFAULT_BACKGROUND_COLOR, volume: DEFAULT_VOLUME },
      )
      .then(data => {
        setSuccessActionMessages(data.success);
        setAudioSettings(planogramVersionToAudioSettings(data.planogramVersion));
      })
      .catch(handleServerError);

  return (
    <AudioSettingsCard
      onSaveAudioSettings={onSaveAudioSettings}
      audioSettings={audioSettings}
      otherAssets={planogramVersion.otherAssets}
      expanded={expanded}
      onToggleExpanded={() => setExpanded(prevState => !prevState)}
    />
  );
}
